import React, { Fragment, useEffect, useState, CSSProperties, useRef } from 'react';
import { images, spacing, color } from '../../Layouts/theme';
import {
  MOBILE_HEADER_TH,
  MOBILE_HEADER_EN,
  HEADER_2_EN,
  HEADER_2_TH,
  BODY_2_EN,
  BODY_2_TH,
  NON_PADDING_LEFT_RIGHT,
  MOBILE_CONTENT_CARD,
  RightImage,
  SpaceBetweenContent,
  SPACE_BETWEEN_CONTENT,
  BUTTON_STYLE,
} from '../../Layouts/styled/main';
import { Container, Row, Col } from 'react-bootstrap';
import { articles as articleData } from '../../db/articles';
import { useTranslation } from 'react-i18next';
import { navigate, graphql } from 'gatsby';
import i18next from 'i18next';
import ContentImageLeft from '../../components/card-content/content-image-left';
import ContentImageRight from '../../components/card-content/content-image-right';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { getSourceImage } from '../../utils/image-sharp';

const IMAGE_STYLE: CSSProperties = {
  borderRadius: `80px ${spacing[0]}px`,
};

const LoadMoreSection = styled.section`
  padding-top: 50px;
  padding-bottom: 50px;
  @media (min-width: 576px) {
    padding-top: ${spacing[8]}px;
    padding-bottom: ${spacing[8]}px;
  }
`;

const LINE: CSSProperties = {
  borderTop: `1px solid ${color.mainColor4}`,
  margin: 0,
};

const Article = ({ data }) => {
  const { t } = useTranslation();
  const [loadMore, setLoadMore] = useState<number>(5);
  const [articles, setArticles] = useState<any[]>([]);
  const prevScrollY = useRef(0);
  const [goingUp, setGoingUp] = useState(false);

  useEffect(() => {
    articleData.sort(
      (a, b) =>
        (+a.article_id < +b.article_id) ? 1 :
          ((+b.article_id < +a.article_id) ? -1 : 0)
    )
    setArticles(articleData);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }

      prevScrollY.current = currentScrollY;

      if (prevScrollY.current >= 350 && goingUp === false && loadMore <= articleData.length) {
        // setLoadMore(loadMore + 3);
        setLoadMore(loadMore + 3);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp, articleData.length, loadMore]);

  const onChangeSeeMore = (id: string) => {
    navigate(`/article/${id}`);
  };

  const BUTTON: CSSProperties = {
    ...BUTTON_STYLE,
    fontFamily: i18next?.language === 'th' ? 'KanitLight' : 'RobotaRegular',
  };

  const edges = data.allFile.edges;

  return (
    <main className={'main'}>
      {/* //? section 1 */}
      <div className="d-none d-md-block" style={{ paddingTop: spacing[5] }} />
      <ContentImageLeft
        img={{
          src: !articles[0]?.small_square_image_path
            ? images.defaultBody
            : require(`../../images/articles/${articles[0].small_square_image_path}`)
              .default,
          alt: 'article-image-0',
        }}
        source={getSourceImage(`${articles[0]?.small_square_image_path}`, edges)}
        title_name={
          articles[0] ? articles[0][`article_name_${i18next.language}`] : ''
        }
        content={
          articles[0] ? articles[0][`summary_content_${i18next.language}`] : ''
        }
        is_more_detail
        detail_name={t('see_more')}
        onClick={() => onChangeSeeMore(articles[0].article_id)}
        content_padding_top={spacing[7]}
      />

      {/* //? section 2 */}
      <SpaceBetweenContent />
      <ContentImageRight
        img={{
          src: !articles[1]?.small_square_image_path
            ? images.defaultBody
            : require(`../../images/articles/${articles[1].small_square_image_path}`)
              .default,
          alt: 'article-image-0',
        }}
        source={getSourceImage(`${articles[1]?.small_square_image_path}`, edges)}
        title_name={
          articles[1] ? articles[1][`article_name_${i18next.language}`] : ''
        }
        content={
          articles[1] ? articles[1][`summary_content_${i18next.language}`] : ''
        }
        is_more_detail
        detail_name={t('see_more')}
        onClick={() => onChangeSeeMore(articles[1].article_id)}
        content_padding_top={spacing[7]}
      />

      {/* //? section 3 */}

      {/* //? for screen size xs-md */}
      {articles.slice(2, loadMore).map((article: any, index: number) => {
        return (
          <section className="d-md-none d-block" key={`article-index-${index}`}>
            <Container fluid>
              <Row
                className="justify-content-center"
                style={{ paddingTop: 60 }}
                key={`article-index-${index}`}
              >
                <Col
                  xs="12"
                  style={{
                    marginTop: index === 0 ? spacing[0] : -spacing[7],
                  }}
                >
                  <div
                    data-aos="fade-right"
                    style={{ width: '100%', height: 360 }}
                  >
                    <Img
                      fluid={getSourceImage(
                        article.small_square_image_path,
                        edges,
                      )}
                      alt={
                        'ouay-article-lg-image' +
                        article.small_square_image_path
                      }
                      style={{
                        position: undefined,
                      }}
                      imgStyle={IMAGE_STYLE}
                    />
                  </div>
                </Col>
                <Col xs="1" className="d-none d-md-block" />
                <Col xs="10" md="4">
                  <Container
                    fluid
                    style={{
                      paddingTop: 40,
                      paddingBottom: 50,
                      alignContent: 'center',
                    }}
                  >
                    <Row>
                      <Col xs="12" className="text-spacing-l-r">
                        <div data-aos="fade-up">
                          <div
                            style={
                              i18next?.language === 'th'
                                ? MOBILE_HEADER_TH
                                : MOBILE_HEADER_EN
                            }
                          >
                            {article
                              ? article[`article_name_${i18next.language}`]
                              : ''}
                          </div>
                          <br />
                          <div
                            dangerouslySetInnerHTML={{
                              __html: article
                                ? article[`summary_content_${i18next.language}`]
                                : '',
                            }}
                            style={
                              i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col xs="12" className="text-spacing-l-r">
                        <button
                          style={BUTTON}
                          onClick={() => onChangeSeeMore(article.article_id)}
                        >
                          {t('see_more')}
                        </button>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col xs="1" className="d-none d-md-block" />
              </Row>
            </Container>
          </section>
        );
      })}

      {/* //? for screen size md++ */}
      <section className="d-none d-md-block">
        <Container fluid>
          <Row className="justify-content-center">
            <Col md="10" style={{ ...NON_PADDING_LEFT_RIGHT, maxWidth: 1080 }}>
              <Container fluid style={NON_PADDING_LEFT_RIGHT}>
                <Row className="justify-content-between">
                  {articles
                    .slice(2, loadMore)
                    .map((article: any, index: number) => {
                      return (
                        <Col
                          xs="12"
                          md="4"
                          style={SPACE_BETWEEN_CONTENT}
                          key={`article-index-${index}`}
                        >
                          <Container fluid style={{ alignContent: 'center' }}>
                            <Row>
                              <Col xs="12">
                                <div
                                  data-aos="fade-up"
                                  style={{ width: '100%', height: 'auto' }}
                                >
                                  <Img
                                    fluid={getSourceImage(
                                      article.small_square_image_path,
                                      edges,
                                    )}
                                    alt={
                                      'ouay-article-lg-image' +
                                      article.small_square_image_path
                                    }
                                    style={{
                                      position: undefined,
                                    }}
                                    imgStyle={IMAGE_STYLE}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row
                              style={{
                                paddingTop: spacing[6],
                                paddingBottom: 60,
                              }}
                            >
                              <Col xs="12">
                                <div data-aos="fade-up">
                                  <div
                                    style={
                                      i18next?.language === 'th'
                                        ? HEADER_2_TH
                                        : HEADER_2_EN
                                    }
                                  >
                                    {article
                                      ? article[
                                      `article_name_${i18next.language}`
                                      ]
                                      : ''}
                                  </div>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: article
                                        ? article[
                                        `summary_content_${i18next.language}`
                                        ]
                                        : '',
                                    }}
                                    style={{
                                      ...(i18next?.language === 'th'
                                        ? BODY_2_TH
                                        : BODY_2_EN),
                                      paddingTop: spacing[4]
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Container>
                          <Container
                            fluid
                            style={{
                              position: 'absolute',
                              bottom: 0,
                              zIndex: 999,
                            }}
                          >
                            <Row>
                              <Col xs="12">
                                <button
                                  style={BUTTON}
                                  onClick={() => onChangeSeeMore(article.article_id)}
                                >
                                  {t('see_more')}
                                </button>
                              </Col>
                            </Row>
                          </Container>
                        </Col>
                      );
                    })}
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </section>
      <div style={SPACE_BETWEEN_CONTENT} className="d-none d-md-block" />
    </main>
  );
};
export default Article;

export const query = graphql`
  query {
    allFile(
      filter: { relativePath: { regex: "/articles/" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 630, quality: 100) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
